<template>
  <div class="header">
    <div class="header_left">
      <a href="javascript:;" style="display:flex;align-items:center" @click="reload">
        <el-image src="/img/logo.png?v=0.1" style="width:60px;height:60px;"></el-image>
        <span>{{ title }}</span>
      </a>
      <span class="region">
        <!-- <i class="el-icon-location-outline"></i> -->

        <!-- <department-tree :is-text="true" :is-all="true" @confirm="popoverHide"></department-tree> -->
      </span>
    </div>
    <!-- <div class="header_right">
      <router-link
        v-for="(item, index) in menu"
        :key="index"
        :class="{ active: pathname === item.pathname }"
        :to="{ name: item.pathname }"
        >{{ item.name }}</router-link
      >
    </div> -->
    <div class="header_tools">
      <!-- 视频中心 -->
      <!-- <RtcCenter ref="rtc_center" class="header_tools_item" /> -->
      <!-- 实时事件 -->
      <el-badge :hidden="noCheckCount==0" :value="noCheckCount" :max="99" class="header_tools_item">
        <el-button
          icon="el-icon-video-camera"
          @click="realTimeCases"
        >实时事件
        </el-button>
      </el-badge>
      <el-button
        style="height: 40px"
        icon="el-icon-data-analysis"
        @click="rank"
      >排行榜</el-button>
      <el-dropdown v-if="userData" trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          <el-avatar :size="30" icon="el-icon-user-solid" style="margin-right:10px"></el-avatar> {{ userData.realname }} <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="change" icon="el-icon-user">修改密码</el-dropdown-item>
          <el-dropdown-item v-if="userData && userData.empower.indexOf('0')>-1" command="manage" icon="el-icon-setting">运维管理</el-dropdown-item>
          <el-dropdown-item command="logout" icon="el-icon-switch-button">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible" @close="resetForm">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input v-model.trim="form.oldPassword" type="password" autocomplete="off" />
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model.trim="form.newPassword" type="password" autocomplete="off" />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input v-model.trim="form.confirmPassword" type="password" autocomplete="off" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitChange">确 定</el-button>
      </div>
    </el-dialog>
    <!-- <audio
      ref="audio"
      :src="'/voice/notice.mp3'"
      controls
      :hidden="true"
    ></audio> -->
  </div>
</template>
<script>
import casesApi from '@/api/cases'
import api from '@/api/account'
// import DepartmentTree from '@/components/DepartmentTree'
// import RtcCenter from '@/components/RtcCenter'

import EventBus from '@/utils/event-bus.js'
import { mapGetters } from 'vuex'
import moment from 'moment'
// import Cases from '@/components/Cases/cases'

export default {
  name: 'HeaderTop',
  // components: { DepartmentTree },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    return {
      title: process.env.VUE_APP_TITLE,
      menu: [
        { name: '综合展示', pathname: 'index' },
        { name: '部门管理', pathname: 'department' },
        { name: '人员管理', pathname: 'account' }
      ],
      rtc_visable: false,
      pathname: '',
      noCheckCount: 0,
      time: '',
      form: {
        confirmPassword: ''
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '长度至少为 6 个字符', trigger: 'blur' }
        ],
        confirmPassword: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      dialogFormVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'userData', 'casesConfig', 'departmentId'
    ])
  },
  watch: {
    casesConfig() {
      this.getNoCheckCount()
    }
  },
  beforeDestroy() {
    clearInterval(this.caseWatch)
  },
  created() {
    this.getNoCheckCount()
    this.caseWatch = setInterval(() => {
      this.getNoCheckCount()
      this.getNewCase()
    }, 10000)
  },
  methods: {
    showRTC() {
      this.rtc_visable = true
    },
    realTimeCases() {
      EventBus.$emit('searchCases')
    },
    rank() {
      EventBus.$emit('showRank')
    },
    getNoCheckCount() {
      casesApi.getNoCheckCount({ ...this.casesConfig, ...{ department_id: this.departmentId }}).then(response => {
        this.noCheckCount = response.data
      })
    },
    getNewCase() {
      casesApi.getNewCase({ time: this.time || moment().format('YYYY-MM-DD HH:mm:ss') }).then(response => {
        this.time = moment().format('YYYY-MM-DD HH:mm:ss')
        if (response.data.length) {
          if (this.casesConfig) {
            if (this.casesConfig.voiceRemind) {
              // 声音提醒
              // this.$refs.audio.currentTime = 0// 从头开始播放
              // this.$refs.audio.play() // 播放
              // setTimeout(() => {
              //   this.$refs.audio.pause()// 停止
              // }, 3000)
            }
            if (this.casesConfig.autoLocation) {
              if (window.view) {
                // const cases = new Cases(window.view)
                // cases.initGroup(response.data)
              }
            }
          }
          // 定位
        }
      })
    },
    popoverHide(checkedIds, checkedNode) {
      this.$store.commit('global/SET_DEPARTMENT_ID', checkedIds)
    },
    handleCommand(command) {
      if (command === 'logout') {
        this.logout()
      } else if (command === 'manage') {
        this.manage()
      } else if (command === 'change') {
        this.changePwd()
      }
    },
    changePwd() {
      this.resetForm()
      this.dialogFormVisible = true
    },
    manage() {
      window.open(process.env.VUE_APP_ADMIN_WEB)
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.path}`)
    },
    submitChange() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          api.ChangePassword(this.form).then(response => {
            this.$message({
              message: response.message,
              type: 'success'
            })
            this.$refs.ruleForm.resetFields()
            setTimeout(() => {
              this.logout()
            }, 1000)
          })
        }
      })
    },
    resetForm() {
      this.form = { confirmPassword: '' }
    },
    reload() {
      location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  height: 60px;
  line-height: 60px;
  color: #fff;
  // background-color: rgba($color: #000000, $alpha: 0.5);
  background-color: #228cfd;
  display: flex;
  padding: 0 30px;
  &_left {
    font-size: 22px;
    display: flex;
    align-items: center;
    .region{
      display: flex;
      align-items: center;
      font-size: 18px;
      margin-left: 10px;
    }
  }
  &_right {
    display: flex;
    margin-left: 100px;
    a {
      color: #fff;
      display: inline-block;
      padding: 0 20px;
      text-decoration: none;
      &.active {
        background-color: #008abf;
      }
    }
  }
  &_tools {
    flex: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &_item {
      cursor: pointer;
      margin-right: 20px;
    }
    .el-dropdown-link{
      cursor: pointer;
      margin-left:30px;
      color:#fff;
      display: flex;
      align-items: center;
    }
  }
}
.el-badge {
  /deep/.el-badge__content {
    top: 12px;
  }
}
</style>
