import request from '@/utils/request'
const url = '/front/cases/'
const api = {
  uploadFile(data){
    const formData=new FormData();
    formData.append('file',data)
    return request({
      url: url + 'resources/upload',
      method: 'post',
      data:formData
    })
  },
  Query(data) {
    return request({
      url: url + 'Query',
      method: 'post',
      data
    })
  },
  GetList(data) {
    return request({
      url: url + 'GetList',
      method: 'post',
      data
    })
  },
  Summary(data) {
    return request({
      url: url + 'summary',
      method: 'post',
      data
    })
  },
  Update(data) {
    return request({
      url: url + 'save',
      method: 'post',
      data
    })
  },
  Check(data) {
    return request({
      url: url + 'check',
      method: 'post',
      data
    })
  },
  Create(data) {
    return request({
      url: url + 'save',
      method: 'post',
      data
    })
  },
  Fetch(id) {
    return request({
      url: url + 'get',
      method: 'get',
      params: { id }
    })
  },
  Refund(id) {
    return request({
      url: url + 'refund',
      method: 'post',
      data: { id }
    })
  },
  Delete(data) {
    return request({
      url: url + 'delete',
      method: 'post',
      data
    })
  },
  GetBufferData(data) {
    return request({
      url: url + 'GetBufferData',
      method: 'post',
      data
    })
  },
  GetBufferAllData(data) {
    return request({
      url: url + 'GetBufferAllData',
      method: 'post',
      data
    })
  },
  getCountByType(data) {
    return request({
      url: url + 'getCountByType',
      method: 'post',
      data
    })
  },
  getNoCheckCount(data) {
    return request({
      url: url + 'getNoCheckCount',
      method: 'post',
      data
    })
  },
  SummaryStatistics(data) {
    return request({
      url: url + 'summaryStatistics',
      method: 'post',
      data
    })
  },
  getCheckDetail(data) {
    return request({
      url: url + 'getCheckDetail',
      method: 'post',
      data
    })
  },
  getNewCase(data) {
    return request({
      url: url + 'getNewCase',
      method: 'post',
      data
    })
  },
  Excel(data) {
    return request({
      url: url + 'Excel',
      method: 'post',
      data
    })
  }
}
export default api
