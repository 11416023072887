/* eslint-disable */
import router from './router'
import store from './store'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth' // get token from cookie 从cookie 中获得token

const whiteList = ['/login', '/auth-redirect', '/401'] // no redirect whitelist 不需要登录的白名单

router.beforeEach(async (to, from, next) => {
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/401') {
      next()
      return
    }
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      const userData = store.getters.userData
      if (userData) {
        if (userData.empower.indexOf('2') > -1) {
          next()
        } else {
          next('/401')
        }
      } else {
        try {
          await store.dispatch('user/getInfo')

          await store.dispatch('global/getCasesConfig')

          next({ ...to, replace: true })
        } catch (error) {
          // // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          console.info(error)
          next(`/login?redirect=${to.path}`)
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // 在自由登录白名单中直接登录
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
    }
  }

})

