const Utils = {
  // 生成uid
  generateNumber(len) {
    var numLen = len || 8
    var generateNum = Math.ceil(Math.random() * Math.pow(10, numLen))
    return generateNum < Math.pow(10, numLen - 1)
      ? Utils.generateNumber(numLen)
      : generateNum
  }
}
export default Utils
