import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'

Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Dashboard'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/401',
    component: () => import('@/views/errorPage/401')
  },
  {
    path: '/index',
    component: Layout,
    redirect: '/index/dashboard',
    name: 'index',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/map/index'),
        name: 'Dashboard'
      }
    ]
  }
]

export default new Router({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
})
