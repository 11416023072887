import Vue from 'vue'

import Element from 'element-ui'

import App from './App.vue'
import router from './router'
import store from './store'

// import esriLoader from 'esri-loader'

Vue.use(Element, {})
import 'element-ui/lib/theme-chalk/index.css'

import '@arcgis/core/assets/esri/themes/light/main.css'
// import './assets/arcgis/esri/themes/light/main.css'

import esriConfig from '@arcgis/core/config'
esriConfig.fontsUrl = 'http://cdnfonts.arcgis.nmgjoin.com'
esriConfig.assetsPath = 'http://cdnjs.arcgis.nmgjoin.com/4.21/@arcgis/core/assets'

// // esriConfig.request.proxyUrl = "http://gisdemo.aa";
// esriConfig.request.proxyRules.push({
//   urlPrefix:'gisdemo.aa',
//   proxyUrl:'http://gisdemo.aa/front/Account/getImg'
// })

// esriConfig.apiKey = "AAPK05e933cf44414d5f9c71a570498685f8OSBaSf0vEe7lYX0WYn1n99gdXoGtvLuZcIbK0MRzbC6gVlTzBY6EarobNGgP1kHm";

Vue.config.productionTip = false

import './icons' // icon 引入图标
import './permission' // permission control

import '@/assets/css/styles/index.scss' // global css

// console.log(esriLoader)
// esriLoader.loadScript({
//   url: 'https://js.arcgis.com/4.12/init.js',
//   dojoConfig: {
//     async: true
//   }
// })
// esriLoader.loadCss('https://js.arcgis.com/4.12/esri/themes/light/main.css')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
