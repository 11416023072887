<template>
  <div id="app">
    <div class="container">
      <headerTop></headerTop>
      <div class="container-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import headerTop from './components/header'

export default {
  name: 'Layout',
  components: {
    headerTop
  }
}
</script>

<style lang="scss">
/* 去除地图，组件点击黑边框 */
.esri-view .esri-view-surface--inset-outline:focus::after{
  outline: auto 0px Highlight !important;
  outline: auto 0px -webkit-focus-ring-color !important;
}
.container{
  height: 100vh;
  position: relative;
  &-content{
    height: calc(100vh - 60px);
  }
}
</style>
