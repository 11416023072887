import request from '@/utils/request'
const url = '/front/config/'

const api = {
  Fetch(id) {
    return request({
      url: url + 'get',
      method: 'get',
      params: { id }
    })
  },
  Save(data) {
    return request({
      url: url + 'save',
      method: 'post',
      data
    })
  }
}

export default api
