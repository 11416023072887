import Cookies from 'js-cookie'

const TokenKey = 'jwt'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  console.log('process.env.VUE_APP_DOMAIN', process.env.VUE_APP_DOMAIN)
  return Cookies.set(TokenKey, token, { path: '', domain: process.env.VUE_APP_DOMAIN })
}

export function removeToken() {
  Cookies.remove(TokenKey)
  return Cookies.remove(TokenKey, { path: '', domain: process.env.VUE_APP_DOMAIN }) // 删除总台cookie
}
