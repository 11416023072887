import request from '@/utils/request'
const url = '/front/account/'
const api = {
  GetList(data) {
    return request({
      url: url + 'GetList',
      method: 'post',
      data
    })
  },
  Login(data) {
    return request({
      url: '/admin/login/login',
      method: 'post',
      data
    })
  },
  GetInfo(data) {
    return request({
      url: '/admin/account/info',
      method: 'post',
      data
    })
  },
  Logout() {
    return request({
      url: 'admin/login/logout',
      method: 'post'
    })
  },
  Query(data) {
    return request({
      url: url + 'Query',
      method: 'post',
      data
    })
  },
  QueryAll(data) {
    return request({
      url: url + 'QueryAll',
      method: 'post',
      data
    })
  },
  Update(data) {
    return request({
      url: url + 'save',
      method: 'post',
      data
    })
  },
  Create(data) {
    return request({
      url: url + 'save',
      method: 'post',
      data
    })
  },
  Fetch(id) {
    return request({
      url: url + 'get',
      method: 'get',
      params: { id }
    })
  },
  Refund(id) {
    return request({
      url: url + 'refund',
      method: 'post',
      data: { id }
    })
  },
  Delete(data) {
    return request({
      url: url + 'delete',
      method: 'post',
      data
    })
  },
  SummaryGuarder(data) {
    return request({
      url: url + 'summaryGuarder',
      method: 'post',
      data
    })
  },
  GetLocation(data) {
    return request({
      url: url + 'getLocation',
      method: 'post',
      data
    })
  },
  SummaryByPerson(data) {
    return request({
      url: url + 'summaryByPerson',
      method: 'post',
      data
    })
  },
  ChangePassword(data) {
    return request({
      url: url + 'changePassword',
      method: 'post',
      data
    })
  },
  GetBufferData(data) {
    return request({
      url: url + 'GetBufferData',
      method: 'post',
      data
    })
  },
  GetBufferAllData(data) {
    return request({
      url: url + 'GetBufferAllData',
      method: 'post',
      data
    })
  },
  SystemLog(data) {
    return request({
      url: url + 'addSystemLog',
      method: 'post',
      data
    })
  },
  ResetPwd(data) {
    return request({
      url: url + 'resetPwd',
      method: 'post',
      data
    })
  }
}
export default api
