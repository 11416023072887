import Utils from '@/components/RtcCenter/Utils.js'

const state = {
  repetitionClick: false, // 按钮重复点击标记
  Conference: false, // 选择的呼叫模式  false: p2p呼叫  true: 多人呼叫
  Calling: false, // 正在通话中(标识)
  JoinRTCChannel: false, // 加入 RTC 频道(标识)
  rtcClient: null, // 存放 RTC 客户端
  rtmClient: null, // 存放 RTM 客户端
  rtmChannel: null, // 存放 RTM 频道实例
  localInvitation: null, // 存放主叫邀请实例
  invitationClearTimeouts: {}, // 存放多个有效期计时(定时器仅多人呼叫使用)
  bigMutiUser: {}, // 大屏展示(仅多人呼叫使用)
  recordUser: [], // 记录页面展示的音视频轨道(仅多人呼叫使用)
  remoteInvitation: null, // 存放被叫邀请实例
  invitationTimeout: 58 * 1000, // 邀请有效期限
  invitationClearTimeout: null, // 邀请有效期计时(定时器)
  callDurationInterval: 0, // 通话时长
  callDurationClearInterval: null, // 通话时长计时(定时器)
  ownUserId: '', // 自己的用户ID - 这里需要转字符串
  peerUserId: '', // 远端的用户的ID
  // peerUserIdRTM: "", // 远端用户RTM的id
  channelId: '' + Utils.generateNumber(9), // 频道房间
  // RTC 本地采集的音视频轨道
  localTracks: {
    videoTrack: null,
    audioTrack: null
  },
  // RTC 远端视频轨道
  remoteVideoTracks: null,
  // 设置
  setting: {
    // p2p设置
    // 是否显示视频相关数据
    videoDataShow: false,
    // 数据显示定时器
    videoStatsInterval: null,

    videoSize: [1920, 1080], // 设置视频采集的分辨率大小
    audioDevice: 'default', // 设置音频设备ID
    videoDevice: 'default', // 设置视频设备ID
    // 多人设置
    enableAudio: true, // 声音开关
    enableVideo: true // 视频开关
  },
  invitationUserIds: [], // 存放多人通话的用户id
  // 接听后频道无人
  callChannelPro: null,
  callChannelProTime: 15000,

  localwork: true, // 本地网络
  lineworkRTM: true, // RTM网络连接状态
  lineworkRTC: true, // RTC网络连接状态
  // 断网相关(p2p)
  network: {
    // type: "rtm", // 断网时所在页面 rtm rtc
    calltype: 0, // 呼叫类型 0:主叫 1:被叫
    status: 0, // 当前状态 呼叫中:1 已接受:2 挂断:0
    Mode: 0 // 通话类型 语音、视频
  },
  networkSet: null, // 断网定时挂断
  networkTime: 30000, // 断网定时挂断时间
  remodVideoEnd: null, // 远端断网定时器
  remodVideoEndTime: 10000,
  networkReconnection: null, // 断网重连后发送信息无响应
  networkReconnectionTime: 10000
}
const mutations = {

}
const actions = {

}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
