<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
  outline: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
::-webkit-scrollbar-thumb:horizontal,::-webkit-scrollbar-thumb:vertical{
    width: 4px;
    background-color: #CCC;
    -webkit-border-radius: 4px;
    outline: 2px solid #fff;
    outline-offset: -2px;
    border: 2px solid #fff;
}
::-webkit-scrollbar{
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track-piece{
  background-color: #fff;
  -webkit-border-radius: 0;
}
.esri-view-height-less-than-medium{
  .esri-popup__main-container{
    max-height: 500px !important;
  }
}

</style>
