
import api from '@/api/config'

const state = {
  departmentId: 0,
  regionCode: '',
  casesConfig: {}
}

const mutations = {
  SET_DEPARTMENT_ID: (state, id) => {
    state.departmentId = id
  },
  SET_REGION_CODE: (state, code) => {
    state.regionCode = code
  },
  SET_CASE_CONFIG: (state, data) => {
    state.casesConfig = data
  }
}

const actions = {
  getCasesConfig({ commit }) {
    return new Promise((resolve, reject) => {
      api.Fetch('cases').then(response => {
        if (response.data) {
          commit('SET_CASE_CONFIG', response.data)
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  setCasesConfig({ commit }, data) {
    return new Promise((resolve, reject) => {
      const params = {
        id: 'cases',
        content: JSON.stringify(data)
      }
      api.Save(params).then(response => {
        commit('SET_CASE_CONFIG', { ...data })
        resolve()
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
